/* Base styles */
:root {
  --bg-color: #1e1e1e;
  --text-color: #e0e0e0;
  --sidebar-bg: #252526;
  --border-color: #333;
  --input-bg: #2d2d2d;
  --hover-bg: #3a3a3a;
  --accent-color: #57c5b6;
  --accent-hover: #45a29e;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Roboto', Arial, sans-serif;
}

/* App layout */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}

/* Header styles */
.app-header {
  position: absolute;
  top: 10px;
  left: 80px;
  display: flex;
  align-items: center;
}

.app-logo {
  height: 80px;
  margin: 0 0 30px 0;
}

.app-header h1 {
  color: var(--text-color);
  margin: 0;
  font-size: 1.5rem;
  padding-top: 25px;
}

.tag-line {
  margin: 5px 0 0;
}

/* Sidebar styles */
.sidebar {
  width: 60px;
  background-color: var(--sidebar-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
}

.sidebar-top,
.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar button {
  background: none;
  border: none;
  color: #858585;
  font-size: 24px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.sidebar button:hover {
  color: var(--text-color);
}

.sidebar button.active {
  color: var(--accent-color);
}

/* Split screen layout */
.split-screen {
  display: flex;
  flex: 1;
  margin-top: 100px;
}

.split-screen__left,
.split-screen__right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.split-screen__left {
  border-right: 1px solid var(--border-color);
}

.split-screen__left-top,
.split-screen__left-bottom,
.split-screen__right {
  padding: 20px;
}

.split-screen__left-top {
  flex: 0 0 auto;
  border-bottom: 1px solid var(--border-color);
  transition: flex 0.3s ease;
  padding-bottom: 10px;
}

.split-screen__left-top.collapsed {
  flex: 0 0 auto;
  transition: flex 0.3s ease;
  padding-bottom: 0;
}

.split-screen__left-bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

/* Common styles for job description, resume, and suggestions */
.job-description,
.resume,
.suggestions {
  height: 100%;
  font-family: Quicksand, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
}

h2 {
  color: var(--text-color);
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  color: var(--text-color);
  resize: none;
}

/* Upload area styles */
.upload-area {
  border: 2px dashed #4a4a4a;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-box {
  margin-top: 20px;
}

.upload-button {
  background-color: var(--input-bg);
  color: var(--text-color);
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: var(--hover-bg);
}

/* Suggestions styles */
.suggestions {
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid var(--border-color);
}

.suggestions h2 {
  margin-top: 0;
}

.suggestions ul {
  list-style-type: none;
  padding: 0;
  max-height: 50vh;
  overflow-y: auto;
}

.suggestions li {
  background-color: var(--input-bg);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

/* Spinner styles */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner-inner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Hide scrollbars */
.split-screen__left-top,
.split-screen__left-bottom,
.split-screen__right,
.job-description textarea,
.resume__content,
.suggestions__content,
.suggestions ul {
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-y: auto;
}

.split-screen__left-top::-webkit-scrollbar,
.split-screen__left-bottom::-webkit-scrollbar,
.split-screen__right::-webkit-scrollbar,
.job-description textarea::-webkit-scrollbar,
.resume__content::-webkit-scrollbar,
.suggestions__content::-webkit-scrollbar,
.suggestions ul::-webkit-scrollbar {
  display: none;
}

/* Settings Modal styles */
.settings-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--input-bg);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 300px;
}

.settings-modal h3 {
  color: var(--accent-color);
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.settings-content {
  margin-bottom: 20px;
}

.settings-content label {
  display: block;
  margin-bottom: 5px;
  color: var(--text-color);
}

.model-select,
.api-key-input {
  width: 100%;
  padding: 10px;
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 15px;
}

.save-button,
.close-button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  color: white;
}

.save-button {
  background-color: var(--accent-color);
}

.save-button:hover {
  background-color: var(--accent-hover);
}

.close-button {
  background-color: #4a4a4a;
}

.close-button:hover {
  background-color: #5a5a5a;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Suggestion action buttons */
.suggestion__actions button,
.suggestion__example button {
  display: block;
  padding: 10px;
  background-color: var(--accent-color);
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  font-size: 12px;
}

.suggestion__actions button:hover,
.suggestion__example button:hover {
  background-color: var(--accent-hover);
}

/* Footer styles */
footer {
  color: var(--text-color);
  text-align: center;
  font-size: 0.7rem;
}