.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: white;
    color: black;
}

.header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem; /* Reduced from 2rem */
    width: 100%;
}

.landing-logo {
    width: 80px;
    height: auto;
    margin-right: 1rem;
    padding-top: 15px;
}

.landing-page h1 {
    font-size: 3rem;
    color: #333;
    margin: 0;
    margin-bottom: 0.5rem; /* Added to reduce gap */
}

.landing-page h2 {
    color: #333;
    margin: 0;
    font-size: 1.5rem; /* Adjusted size for subtitle */
    margin-top: 0.5rem; /* Added to reduce gap */
}

.landing-page main {
    max-width: 800px;
    width: 100%;
    color: #333;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-page main h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #333;
}

.landing-page main h2 {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #333;
}

.landing-page main p, .landing-page main li {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.landing-page main ul {
    padding-left: 2rem;
    margin-bottom: 1rem;
}

.cta-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: white;
    background-color: #007bff;
    text-decoration: none;
    border-radius: 25px;
    transition: all 0.3s ease;
    margin-top: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cta-button svg {
    width: 1em;
    height: 1em;
    margin-right: 8px;
    fill: #ffd700;
}

.landing-page footer {
    margin-top: auto;
    padding-top: 2rem;
    font-size: 0.9rem;
    color: #666;
}