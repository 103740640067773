.mobile-message {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
  }
  
  .mobile-message-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .mobile-message {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    body > #root > div:not(.mobile-message) {
      display: none;
    }
  }